<template>
	<div
		class="flex flex-row-reverse focus:outline-none"
		:tabindex="show ? '0' : '-1'"
		@blur.self.prevent="close"
	>
		<button
			class="btn btn-outline"
			type="button"
			@click.prevent="show = !show"
		>
			<div class="btn-ripple"></div>
			<span class="btn-label">View more</span>
		</button>
		<div v-if="show" class="view-more">
			<div class="view-more-header">
				<span>{{ day }}</span>
				<button
					ref="close"
					type="button"
					class="focus:bg-gray-600 focus:outline-none text-black rounded-full h-6 w-6 flex items-center justify-center ml-auto"
					@click="close"
				>
					<span class="sr-only">Close the view more</span>
					<font-awesome-icon :icon="['fal', 'times']" />
				</button>
			</div>
			<div class="view-more-body ">
				<div
					v-for="event in events"
					:key="event.id"
					class="calendar-cell-content"
				>
					<div class="flex flex-col">
						<span v-if="event.is_multi_day" class="font-bold">
							{{ event.starts_at | toTimestamp('MM/DD h:mm A') }}
							-
							{{ event.ends_at | toTimestamp('MM/DD h:mm A') }}
						</span>
						<span v-else class="font-bold">
							{{ event.starts_at | toTimestamp }}
							-
							{{ event.ends_at | toTimestamp }}
						</span>
						<span
							v-if="isProviderEvent(event.id)"
							:class="[
								getColor(getPrimaryProvider(event.id)).bgClass,
							]"
							class="w-full pl-1 mb-1"
						>
							(P)
							{{
								getPrimaryProvider(event.id)
									| toProviderShortName
							}}</span
						>
						<span
							v-if="hasSecondaryProvider(event.id)"
							:class="[
								getColor(getSecondaryProvider(event.id))
									.bgClass,
							]"
							class="w-full pl-1"
						>
							(S)
							{{
								getSecondaryProvider(event.id)
									| toProviderShortName
							}}</span
						>
						<span
							v-if="isProviderGroupEvent(event.id)"
							:class="[color.bgClass]"
							class="w-full pl-1"
						>
							{{ getProviderGroup(event.id).name }}</span
						>
						<span
							v-if="isLinkedCalendarEvent(event.id)"
							:class="[color.bgClass]"
							class="w-full pl-1"
						>
							{{ getLinkedCalendar(event.id).name }}</span
						>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import colors, { defaultColor } from '@/config/colors'

export default {
	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		...mapGetters('events', [
			'getLinkedCalendar',
			'getProviderGroup',
			'getPrimaryProvider',
			'getSecondaryProvider',
			'hasSecondaryProvider',
			'isLinkedCalendarEvent',
			'isProviderEvent',
			'isProviderGroupEvent',
		]),

		...mapGetters({
			partner: 'partners/active',
		}),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Emit the close event to any parent listeners.
		 *
		 * @return {void}
		 */
		close() {
			if (this.show) {
				this.show = false
			}
		},

		/**
		 * Get the provider's color.
		 *
		 * @param {Object} provider
		 * @param {Number} providerId
		 * @return {String}
		 */
		getColor(provider) {
			return (
				colors.find(color => color.name === provider.color) ||
				defaultColor
			)
		},
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'ViewMore',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The current day to be displayed.
		 *
		 * @type {String}
		 */
		day: {
			type: String,
			required: true,
		},

		/**
		 * The events to be displayed.
		 *
		 * @type {Object}
		 */
		events: {
			type: Array,
			required: true,
		},
	},

	/**
	 * The component's property watchers.
	 *
	 * @type {Object}
	 */
	watch: {
		/**
		 * Focus on the outter element when displaying the pop-up.
		 *
		 * @return {void}
		 */
		show() {
			if (this.show) {
				this.$el.focus()
			}
		},
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			color: defaultColor,
			show: false,
		}
	},
}
</script>

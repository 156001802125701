<template>
	<div class="calendar-overview">
		<div class="calendar-row-header">
			<div class="calendar-header-cell">
				Calendars
			</div>
			<div class="calendar-header-cell first">
				<button class="calendar-action small" @click.prevent="previous">
					<span class="calendar-action-icon small">
						<font-awesome-icon
							class="text-lg"
							fixed-width
							:icon="['far', 'arrow-left']"
							aria-hidden="true"
						/>
					</span>
				</button>
				{{ days[0] }}
			</div>
			<div class="calendar-header-cell center">
				{{ days[1] }}
			</div>
			<div class="calendar-header-cell last">
				{{ days[2] }}
				<button class="calendar-action small" @click.prevent="next">
					<span class="calendar-action-icon small">
						<font-awesome-icon
							class="text-lg"
							fixed-width
							:icon="['far', 'arrow-right']"
							aria-hidden="true"
						/>
					</span>
				</button>
			</div>
		</div>

		<calendar-overview-row
			v-for="calendar in calendars"
			:key="calendar.id"
			:days="days"
			:calendar="calendar"
		>
		</calendar-overview-row>
	</div>
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'
import CalendarOverviewRow from '@/components/CalendarOverviewRow'

/**
 * The format used to display call dates.
 *
 * @type {String}
 */
const DATE_FORMAT = 'ddd, MM/DD/YYYY'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		CalendarOverviewRow,
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Fetch all calendars.
		 *
		 * @return {Array}
		 */
		fetchAllEvents() {
			if (!this.calendars) {
				return
			}

			return this.calendars.map(calendar => {
				try {
					return this.fetchEvents({
						calendar: calendar.id,
						start: moment(this.days[0], DATE_FORMAT).format(
							'YYYY-MM-DD'
						),
						end: moment(this.days[2], DATE_FORMAT).format(
							'YYYY-MM-DD'
						),
						expanded: true,
					})
				} catch (e) {
					this.$alert.error('default.error')
				}
			})
		},

		/**
		 * Get the (next) day from the given date.
		 *
		 * @param {String} date
		 * @return {Object}
		 */
		getNextDay(date) {
			return moment(date, DATE_FORMAT)
				.add(1, 'days')
				.format(DATE_FORMAT)
		},

		/**
		 * Get the previous day from the given date.
		 *
		 * @param {String} date
		 * @return {Object}
		 */
		getPreviousDay(date) {
			return moment(date, DATE_FORMAT)
				.subtract(1, 'days')
				.format(DATE_FORMAT)
		},

		/**
		 * Get the overview's current three dates (starting with today).
		 *
		 * @return {Array}
		 */
		getStartDays() {
			const now = moment()
			const tomorrow = moment().add(1, 'days')
			const twoDaysFromNow = moment().add(2, 'days')

			return [
				now.format(DATE_FORMAT),
				tomorrow.format(DATE_FORMAT),
				twoDaysFromNow.format(DATE_FORMAT),
			]
		},

		/**
		 * Add one day to the current interval.
		 *
		 * @return {void}
		 */
		async next() {
			const first = this.getNextDay(this.days[0])
			const second = this.getNextDay(first)
			const third = this.getNextDay(second)

			this.days = [first, second, third]

			await this.fetchAllEvents()
		},

		/**
		 * Handle the on today click event.
		 *
		 * @return {void}
		 */
		onTodayClick() {
			this.days = this.getStartDays()
		},

		/**
		 * Substract one day to the current interval.
		 *
		 * @return {void}
		 */
		async previous() {
			const first = this.getPreviousDay(this.days[0])

			const second = this.getPreviousDay(this.days[1])

			const third = this.getPreviousDay(this.days[2])

			this.days = [first, second, third]

			await this.fetchAllEvents()
		},

		...mapActions({
			fetchEvents: 'events/get',
			fetchProviders: 'providers/get',
		}),
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'Index',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The current partner's schedule calendars.
		 *
		 * @type {Array}
		 */
		calendars: {
			type: Array,
			default() {
				return []
			},
		},
	},

	/**
	 * The component's before destroy lifecycle hook.
	 *
	 * @return {void}
	 */
	beforeDestroy() {
		this.$app.off('schedules:today', this.onTodayClick)
	},

	/**
	 * The component's created lifecycle hook.
	 *
	 * @return {void}
	 */
	async created() {
		this.$app.on('schedules:today', this.onTodayClick)

		await Promise.all([this.fetchAllEvents()])
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			days: this.getStartDays(),
			tab: 'overview',
		}
	},
}
</script>

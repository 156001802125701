<template>
	<div
		class="flex justify-center focus:outline-none"
		:tabindex="show ? '0' : '-1'"
		@blur.self.prevent="close"
	>
		<button
			type="button"
			class="w-full focus:outline-none"
			@click.prevent="onGroupClick"
		>
			<div class="btn-ripple"></div>
			<span class="btn-label"> <slot /> </span>
		</button>
		<div
			v-if="show"
			ref="popUp"
			class="group-coverage-window"
			:style="groupCoverageTable"
		>
			<div class="group-coverage-header">
				<span>Group Coverage</span>
				<button
					ref="close"
					type="button"
					class="focus:bg-gray-600 focus:outline-none text-black rounded-full h-6 w-6 flex items-center justify-center ml-auto"
					@click="close"
				>
					<span class="sr-only">Close the view more</span>
					<font-awesome-icon :icon="['fal', 'times']" />
				</button>
			</div>
			<div class="group-coverage-body">
				<div class="table-responsive">
					<table class="table">
						<thead>
							<tr>
								<th>Provider</th>
								<th>Primary</th>
								<th>Secondary</th>
							</tr>
						</thead>
						<tbody>
							<template>
								<view-group-coverage-row
									v-for="provider in providerGroupProviders"
									:key="provider.id"
									:covered="provider"
									:members="members"
								/>
							</template>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import ViewGroupCoverageRow from './ViewGroupCoverageRow'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		ViewGroupCoverageRow,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * The group coverage table style for positioning it.
		 *
		 * @return {Object}
		 */
		groupCoverageTable() {
			return {
				top: `${this.top}px`,
				left: this.left ? `${this.left}px` : null,
				right: this.right ? `${this.right}px` : null,
			}
		},

		...mapGetters({ find: 'partners/findProvider' }),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Emit the close event to any parent listeners.
		 *
		 * @return {void}
		 */
		close() {
			if (this.show) {
				this.show = false
				this.top = null
				this.left = null
				this.right = null
			}
		},

		/**
		 * Handle the on group click event.
		 *
		 * @param {Object} evt
		 * @return {void}
		 */
		onGroupClick(evt) {
			this.positionPopUpOnClick(evt.target.getBoundingClientRect())

			this.show = !this.show
		},

		/**
		 * Position the group table according to the button's bounding rectangle.
		 *
		 * @param {DOMRect} rect
		 * @return {void}
		 */
		positionPopUpOnClick(rect) {
			const sidebarWidth = 70
			const popUpWidth = 450

			this.left = null
			this.right = null
			this.top = rect.top + rect.height - window.pageYOffset

			if (rect.left - sidebarWidth + popUpWidth >= window.innerWidth) {
				return (this.right = window.innerWidth - rect.right)
			}

			this.left = rect.left - sidebarWidth
		},
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'ViewGroupCoverageTable',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The event's members.
		 *
		 * @type {Array}
		 */
		members: {
			type: Array,
			default: () => [],
		},

		/**
		 * The calendar's covered providers.
		 *
		 * @type {Array}
		 */
		providerGroupProviders: {
			type: Array,
			required: true,
		},
	},

	/**
	 * The component's property watchers.
	 *
	 * @type {Object}
	 */
	watch: {
		/**
		 * Focus on the outter element when displaying the pop-up.
		 *
		 * @return {void}
		 */
		show() {
			if (this.show) {
				this.$el.focus()
			}
		},
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			coverages: [],
			left: null,
			right: null,
			show: false,
			top: 1,
		}
	},
}
</script>

<template>
	<div class="calendar-cell-content">
		<div class="calendar-cell-content-timestamp">
			{{ event.starts_at | toTimestamp(startFormat) }}
			-
			{{ event.ends_at | toTimestamp(endFormat) }}
		</div>
		<div
			v-if="provider"
			class="calendar-cell-content-provider"
			:class="[primaryColor.bgClass]"
		>
			(P) {{ provider | toProviderShortName }}
		</div>
		<div
			v-if="secondary"
			class="calendar-cell-content-provider"
			:class="[secondaryColor.bgClass]"
		>
			(S) {{ secondary | toProviderShortName }}
		</div>
		<div
			v-if="providerGroup"
			class="calendar-cell-content-provider"
			:class="[color.bgClass]"
		>
			<view-group-coverage-table
				:provider-group-providers="providerGroupProviders"
				:members="members"
			>
				{{ providerGroup.name }}
			</view-group-coverage-table>
		</div>
		<div
			v-if="linkedCalendar"
			class="calendar-cell-content-provider"
			:class="[color.bgClass]"
		>
			{{ linkedPartner }}
		</div>
	</div>
</template>
<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import colors, { defaultColor } from '@/config/colors'
import ViewGroupCoverageTable from './ViewGroupCoverageTable'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		ViewGroupCoverageTable,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Get the current event' calendar.
		 *
		 * @return {Object}
		 */
		calendar() {
			if (!this.event) {
				return
			}

			return this.findCalendar(this.event.calendar_id)
		},

		/**
		 * Get the format to display the event's ending at time.
		 *
		 * @return {String}
		 */
		endFormat() {
			const prefix = this.event.is_multi_day ? 'MM/DD ' : ''

			if (moment(this.event.ends_at).minutes() > 0) {
				return `${prefix}h:mm A`
			}

			return `${prefix}h A`
		},

		/**
		 * Determine if the event has eventables.
		 *
		 * @return {Boolean}
		 */
		hasEventables() {
			if (this.event.eventables.length === 0) {
				return false
			}

			return true
		},

		/**
		 * Get the linked calendar.
		 *
		 * @return {Object}
		 */
		linkedCalendar() {
			return this.getLinkedCalendar(this.event.id)
		},

		/**
		 * Get the linked partner name.
		 *
		 * @return {String}
		 */
		linkedPartner() {
			if (!this.linkedCalendar) {
				return ''
			}

			const partner = this.partner.linked_partners.find(partner => {
				return partner.id === this.linkedCalendar.partner_id
			})

			return partner?.name
		},

		/**
		 * Get the current provider group members if any.
		 *
		 * @return {Object}
		 */
		members() {
			if (!this.providerGroupProviders) {
				return {}
			}

			return this.primaryEventable.members
		},

		/**
		 * Get the provider's primary color.
		 *
		 * @return {String}
		 */
		primaryColor() {
			if (!this.provider) {
				return defaultColor
			}

			return (
				colors.find(color => color.name === this.provider.color) ||
				defaultColor
			)
		},

		/**
		 * Get the primary eventable.
		 *
		 * @return {Object}
		 */
		primaryEventable() {
			if (!this.hasEventables) {
				return
			}

			return this.event.eventables[0]
		},

		/**
		 * Get the primary provider.
		 *
		 * @return {Object}
		 */
		provider() {
			return this.getPrimaryProvider(this.event.id)
		},

		/**
		 * Get the provider group.
		 *
		 * @return {Object}
		 */
		providerGroup() {
			return this.getProviderGroup(this.event.id)
		},

		/**
		 * Get the provider group providers.
		 *
		 * @return {Array}
		 */
		providerGroupProviders() {
			if (!this.hasEventables) {
				return []
			}

			const providerGroup = this.findProviderGroup(
				this.primaryEventable.id
			)

			if (!providerGroup || !Array.isArray(providerGroup.providers)) {
				return []
			}

			return providerGroup.providers
		},

		/**
		 * Get the secondary provider.
		 *
		 * @return {Object}
		 */
		secondary() {
			return this.getSecondaryProvider(this.event.id)
		},

		/**
		 * Get the secondary provider's color.
		 *
		 * @return {String}
		 */
		secondaryColor() {
			if (!this.hasSecondaryProvider(this.event.id)) {
				return defaultColor
			}

			return (
				colors.find(color => color.name === this.secondary.color) ||
				defaultColor
			)
		},

		/**
		 * Get the format to display the event's starting at time.
		 *
		 * @return {String}
		 */
		startFormat() {
			const prefix = this.event.is_multi_day ? 'MM/DD ' : ''

			if (moment(this.event.starts_at).minutes() > 0) {
				return `${prefix}h:mm A`
			}

			return `${prefix}h A`
		},

		...mapGetters({
			findCalendar: 'calendars/find',
		}),

		...mapGetters('events', {
			getLinkedCalendar: 'getLinkedCalendar',
			getProviderGroup: 'getProviderGroup',
			getPrimaryProvider: 'getPrimaryProvider',
			getSecondaryProvider: 'getSecondaryProvider',
			hasSecondaryProvider: 'hasSecondaryProvider',
			isLinkedCalendarEvent: 'isLinkedCalendarEvent',
			isProviderEvent: 'isProviderEvent',
			isProviderGroupEvent: 'isProviderGroupEvent',
		}),

		...mapGetters('partners', {
			findPartner: 'find',
			partner: 'active',
			findProviderGroup: 'findProviderGroup',
		}),
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'CalendarOverviewEvent',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The event model to display.
		 *
		 * @type {Object}
		 */
		event: {
			type: Object,
			required: true,
		},
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			color: defaultColor,
		}
	},
}
</script>

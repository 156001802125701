<template>
	<div class="calendar-row">
		<div class="calendar-cell-title">
			<router-link
				:to="{
					name: 'app.schedules.show',
					params: { id: calendar.id },
				}"
			>
				{{ calendar.name }}
			</router-link>
		</div>
		<div v-for="day in days" :key="day" class="calendar-cell">
			<calendar-overview-event
				v-for="event in (events[day] || []).slice(0, 2)"
				:key="event.id"
				:event="event"
			>
			</calendar-overview-event>
			<view-more
				v-if="(events[day] || []).length > 2"
				:day="day"
				:events="events[day]"
				class="mt-auto"
			/>
		</div>
	</div>
</template>
<script>
import moment from 'moment'
import { sortBy } from 'lodash'
import { mapGetters } from 'vuex'
import ViewMore from '@/components/ViewMore'
import CalendarOverviewEvent from '@/components/CalendarOverviewEvent'

/**
 * The format used to display call dates.
 *
 * @type {String}
 */
const DATE_FORMAT = 'MM/DD/YYYY'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		CalendarOverviewEvent,
		ViewMore,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Get all the events for an specific calendar.
		 *
		 * @param {Number}
		 * @return {Array}
		 */
		events() {
			const events = this.findEventsByCalendarAndDate(
				this.calendar.id,
				this.days[0],
				this.days[2]
			)

			return this.days.reduce((accu, day) => {
				const timestamp = moment.utc(day, DATE_FORMAT)

				accu[day] = []

				events.forEach(event => {
					const start = moment.utc(event.starts_at).local()
					const end = moment.utc(event.ends_at).local()

					const data = {
						...event,
						is_multi_day:
							start.format(DATE_FORMAT) !==
							end.format(DATE_FORMAT),
					}

					if (timestamp.isBetween(start, end, 'day', '[]')) {
						accu[day].push(data)
					}
				})

				accu[day] = sortBy(accu[day], [
					event => moment.utc(event.starts_at).unix(),
				])

				return accu
			}, {})
		},

		...mapGetters({
			findEventsByCalendarAndDate:
				'calendars/findEventsByCalendarAndDate',
		}),
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'CalendarOverviewRow',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The calendar enitity being displayed.
		 *
		 * @type {Object}
		 */
		calendar: {
			type: Object,
			required: true,
		},

		/**
		 * The three dates to display events.
		 *
		 * @type {Array}
		 */
		days: {
			type: Array,
			required: true,
		},
	},

	/**
	 * The component's destroyed hook, clearing out the event data so that
	 * it is not used elsewhere, since the UUIDs here are generated on the front-end.
	 *
	 * @return {void}
	 */
	async destroyed() {
		await this.$store.dispatch('calendars/removeEvents')
		return await this.$store.dispatch('events/reset')
	},
}
</script>

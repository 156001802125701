<template>
	<tr>
		<td>
			<strong>{{ coveredProvider }}</strong>
		</td>
		<td>
			{{ primary }}
		</td>
		<td>
			{{ secondary }}
		</td>
	</tr>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Get the covered provider display name.
		 *
		 * @return {String}
		 */
		coveredProvider() {
			return this.covered.display_name
		},

		/**
		 * Get the row members.
		 *
		 * @return {Array}
		 */
		rowMembers() {
			return this.members.find(
				member => member.provider_id === this.covered.id
			)
		},

		/**
		 * Get the primary covered provider.
		 *
		 * @return {String}
		 */
		primary() {
			if (this.rowMembers.covering_providers[0]) {
				const provider = this.findProvider(
					this.rowMembers.covering_providers[0]?.id
				)

				return provider.display_name
			}

			return this.covered.display_name
		},

		/**
		 * Get the secondary covered provider.
		 *
		 * @return {String}
		 */
		secondary() {
			if (this.rowMembers.covering_providers[1]) {
				const provider = this.findProvider(
					this.rowMembers.covering_providers[1]?.id
				)

				return provider.display_name
			}

			return ''
		},

		...mapGetters({
			findProvider: 'providers/find',
		}),
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'ViewGroupCoverageRow',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The covered provider.
		 *
		 * @type {Object}
		 */
		covered: {
			type: Object,
			required: true,
		},

		/**
		 * The event's members.
		 *
		 * @type {Array}
		 */
		members: {
			type: Array,
			default: () => [],
		},
	},
}
</script>
